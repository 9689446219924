import http from "./http.js";

async function getUsers(page, pageSize, searchTerm, userRole) {
  const response = await http.get("users", {
    params: {
      page,
      pageSize,
      searchTerm,
      userRole,
    },
  });
  return response.data;
}

async function blockUser(id, isBlocked) {
  await http.patch(`users/${id}/is-blocked`, {}, {
    params: {
      isBlocked,
    },
  },
  );
}

export {
  getUsers,
  blockUser,
};
