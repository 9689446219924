<template>
  <v-card
    tile
    flat
    class="pa-3"
  >
    <v-card-title class="pa-0">
      <v-row class="ml-1 py-3">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              icon
              class="ma-0 mr-2"
              v-bind="attrs"
              @click="$router.go(-1)"
              v-on="on"
            >
              <v-icon color="grey darken-1">
                mdi-arrow-left
              </v-icon>
            </v-btn>
          </template>
          <span>Назад</span>
        </v-tooltip>

        <h1 class="headline">
          Редактирование пользователя
        </h1>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          cols="2"
          class="pa-0"
        >
          <v-text-field
            class="mt-4 ml-4"
            :value="user.id"
            label="Ид"
            :disabled="true"
            readonly
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
          class="pa-0 mt-n2"
        >
          <v-text-field
            v-model="user.name"
            class="ma-2 pt-0"
            label="Login"
            hide-details
            outlined
            dense
            readonly
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="6"
          md="6"
          class="pa-0"
        >
          <v-text-field
            :value="user.contactPersonId"
            class="ma-2 pt-0"
            label="Ид пользователя"
            hide-details
            outlined
            dense
            readonly
          />
        </v-col>
        <v-col
          cols="6"
          md="6"
          class="pa-0"
        >
          <v-text-field
            :value="role"
            class="ma-2 pt-0"
            label="Роль"
            hide-details
            outlined
            dense
            readonly
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="6"
          md="6"
          class="py-0"
        >
          <v-switch
            v-model="user.isBlocked"
            :label="user.isBlocked ? 'Заблокирован' : 'Активный'"
            @change="isChanged = true"
          />
        </v-col>
        <v-col
          cols="6"
          md="6"
          class="py-0"
        >
          <v-btn
            class="mt-4"
            small
            @click="sendPassword()"
          >
            Восстановить пароль
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-btn
          :disabled="!isChanged"
          class="ml-2"
          small
          :loading="loading"
          @click="blockUser()"
        >
          Сохранить
        </v-btn>
        <v-btn
          class="ml-2"
          small
          @click="cancel()"
        >
          Отмена
        </v-btn>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import * as usersApi from "../../../backend/usersApi.js";
import * as adminApi from "../../../backend/adminApi.js";

export default ({
  data() {
    return {
      loading: false,
      user: {},
      isChanged: false,
      id: Number,
    };
  },
  computed: {
    role() {
      return this.user.role === 1 ? "Пользователь" : "Администратор";
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.getUser();
  },
  methods: {
    async getUser() {
      this.loading = true;
      this.user = await usersApi.getUser(this.id);
      this.loading = false;
    },
    async blockUser() {
      try {
        this.loading = true;
        await adminApi.blockUser(this.id, this.user.isBlocked);
        await this.getUser();
      } catch {
        this.showError("Ошибка блокировки");
      } finally {
        this.loading = false;
        this.cancel();
      }
    },
    async sendPassword() {
      try {
        this.loading = true;
        const response = await usersApi.sendEmail(this.user.name);
        if (response) {
          this.showInfo("Письмо с ссылкой для восстановления пароля отправлено на email");
        }
      } catch {
        this.showError("Ошибка при отправке письма с ссылкой для восстановления пароля");
      } finally {
        this.loading = false;
      }
    },
    cancel() {
      this.isChanged = false;
      this.$router.go(-1);
    },
  },
});
</script>
